import React from 'react'
import Helmet from 'react-helmet'

import { PageLayout } from 'layouts/PageLayout'

import { HeroSection } from '../components/home/HeroSection'
import { StatementSection } from 'components/home/StatementSection'
// import { ApproachSection } from 'components/home/ApproachSection'
// import { ServicesSection } from 'components/home/ServicesSection'

export default () => {
  return (
    <PageLayout>
      <Helmet title={'Floating Points | Studio'} />

      <HeroSection />
      <StatementSection />
      {/* <ApproachSection /> */}
      {/* <ServicesSection /> */}


    </PageLayout>
  )
}

