import React from 'react'
import styled from '@emotion/styled'

import { media } from 'styles/utils'
import { Title2, Statement } from 'styles/typography'

import { config } from 'styles/config'
import { theme } from 'styles/theme'

import { copywriting } from 'data/copywriting'
import { SectionLayout } from 'layouts/SectionLayout'

const PADDING_NORMAL = '6vw' // screen size < 1440
const PADDING_LARGE = '86.4px' // 6vw at a screen size of 1440px

const Container = styled(SectionLayout)`
  height: calc(100vh - (${theme.measurements.navigationBarHeight}px + ${PADDING_LARGE}));
  padding: 0;
  padding-bottom: ${PADDING_LARGE};

  flex-direction: column;
  justify-content: center;
  min-height: 600px;

  ${media.xlarge`
    height: calc(100vh - (${theme.measurements.navigationBarHeight}px + ${PADDING_NORMAL}));
    // padding-bottom: ${PADDING_NORMAL};
  `}

  ${media[config.mobileBreakpoint]`
    display: flex;
    align-items: center;
    padding: 0;
  `}

  ${media.large`
    width: 100%;
  `}
`

const Hero = styled(Title2)`
  margin-bottom: 0;
  ${media[config.mobileBreakpoint]`
    margin-bottom: calc(${theme.measurements.navigationBarHeight}px + ${PADDING_NORMAL});
  `}
`

export const HeroSection = () => {
  return (
    <Container>
      <Hero className='c-2-8 a-c text-center'>
        {copywriting.home.hero}
      </Hero>
    </Container>
  )
}
