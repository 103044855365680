import React from 'react'
import styled from '@emotion/styled'

import { Statement } from 'styles/typography'
import { config } from 'styles/config'
import { media } from 'styles/utils'

import { SectionLayout } from 'layouts/SectionLayout'

import { copywriting } from 'data/copywriting'

// import { theme } from 'styles/theme'

const ServicesLayout = styled(SectionLayout)`
  height: 80vh;

  /* Landscape */
  @media (max-height: 600px) {
    height: auto !important;
  }

  ${media[config.mobileBreakpoint]`
    height: auto;
  `}
`

const Text = styled(Statement)`
  color: ${p => p.theme.colors.textLight};
  text-align: justify;

  ${media[config.mobileBreakpoint]`
    text-align: left;
  `}
`

export const StatementSection = () => (
  <ServicesLayout>
    <Text
      className='mb-0 a-c c-2-8 light'>
      {copywriting.home.caption}
      <br />
      <br />
      {copywriting.home.statement}
    </Text>
  </ServicesLayout>
)
